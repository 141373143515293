import {onInput} from '@github-ui/onfocus'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

// Show "Specify your industry" custom input on selection of "Other" industry
on('change', '.js-enterprise-trial-industry', function (event) {
  const target = event.target as HTMLSelectElement

  const selectedIndustry = target.value

  const otherIndustryField = document.querySelector<HTMLElement>('.js-enterprise-trial-other-industry-field')
  if (otherIndustryField) {
    otherIndustryField.hidden = selectedIndustry !== otherIndustryField.getAttribute('data-trigger-option-value')!
  }
})

// Hide org invite warning if user selects an org from the dropdown menu
on('change', '.js-enterprise-trial-org-selection', function () {
  const orgInviteWarningWrapper = document.getElementsByClassName(
    'js-enterprise-trial-org-invite-warning',
  )[0] as HTMLDivElement
  if (orgInviteWarningWrapper) {
    orgInviteWarningWrapper.hidden = true
  }
})

onInput('.js-business-shortcode', function (event) {
  const shortcodeInput = event.target as HTMLInputElement

  const adminNameSpan = document.querySelector('.js-business-first-admin-name')
  if (!(adminNameSpan instanceof HTMLElement)) return

  adminNameSpan.textContent = `${shortcodeInput.value}_admin`
})
