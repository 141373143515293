import type {StepChangeResult, WizardState} from './wizard'

/**
 * SinglePageWizardStepContentBase is a base class used to implement a custom step catalyst component.
 */
export class SinglePageWizardStepContentBase extends HTMLElement {
  autoProceedInProgress = false

  connectedCallback() {
    this.setAttribute('data-target', 'single-page-wizard-step.stepContent')
  }

  validateStep(isValid: boolean, autoProceed?: boolean): void {
    // prevent multiple auto-proceed events from being triggered
    if (this.autoProceedInProgress) {
      return
    }

    if (autoProceed && isValid) {
      this.autoProceedInProgress = true
    }

    this.dispatchEvent(
      new CustomEvent('single-page-wizard-step-validate', {bubbles: true, detail: {isValid, autoProceed}}),
    )
  }

  setError(errorMessage: string): void {
    this.autoProceedInProgress = false
    this.dispatchEvent(new CustomEvent('single-page-wizard-step-error', {bubbles: true, detail: {errorMessage}}))
  }

  clearError(): void {
    this.dispatchEvent(new CustomEvent('single-page-wizard-step-error', {bubbles: true}))
  }

  /* eslint-disable-next-line custom-elements/no-method-prefixed-with-on */
  async onStepRendered() {
    return
  }

  // setStepState called from the wizard on each step to provide a reference to updated state
  setStepState(_wizardState: WizardState) {
    return
  }

  // onNextClick is fired off when the "next" button is clicked
  // if "true" is returned, the wizard will move onto the next step
  /* eslint-disable-next-line custom-elements/no-method-prefixed-with-on */
  async onNextClick(): Promise<StepChangeResult<WizardState>> {
    return {proceed: true}
  }

  // onPreviousClick is fired off when the "previous" button is clicked
  // if "true" is returned, the wizard will move back to the previous step
  /* eslint-disable-next-line custom-elements/no-method-prefixed-with-on */
  async onPreviousClick(): Promise<StepChangeResult<WizardState>> {
    return {proceed: true}
  }

  // isStepValid returns a boolean to determine whether the step is
  // in a "valid" state for moving onto the next step.
  isStepValid(): boolean {
    return true
  }
}
