// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

on('upload:setup', '.js-upload-enterprise-installation-user-accounts-upload', function (event) {
  const {form} = event.detail

  const businessID = event.currentTarget.getAttribute('data-business-id')
  if (businessID) {
    form.append('business_id', businessID)
  }

  const installationID = event.currentTarget.getAttribute('data-enterprise-installation-id')
  if (installationID) {
    form.append('enterprise_installation_id', installationID)
  }
})

on('upload:complete', '.js-upload-enterprise-installation-user-accounts-upload', function (event) {
  const {attachment} = event.detail

  const uploadIDInput = event.currentTarget.querySelector<HTMLInputElement>(
    '.js-enterprise-installation-user-accounts-upload-id',
  )!

  uploadIDInput.value = attachment.id

  const installationIDInput = event.currentTarget.querySelector<HTMLInputElement>('.js-enterprise-installation-id')!
  const installationID = event.currentTarget.getAttribute('data-enterprise-installation-id')
  if (installationID) {
    installationIDInput.value = installationID
  }

  // Submit the form to initiate the user account sync.
  const form = uploadIDInput.form
  if (form) form.submit()
})
