import {attr, controller, target} from '@github/catalyst'
import {SinglePageWizardStepContentBase} from '../github/single-page-wizard/step-content-base'
import type {StepChangeResult, WizardState} from '../github/single-page-wizard/wizard'

@controller
class PersonalAccessTokensConfigurationStepElement extends SinglePageWizardStepContentBase {
  @attr key: string
  @target form: HTMLFormElement

  override async onNextClick(): Promise<StepChangeResult<WizardState>> {
    const container = document.querySelector(`li[data-target=${this.key}]`)!

    for (const radioButton of this.form.querySelectorAll<HTMLInputElement>('input')) {
      if (radioButton.checked) {
        const formCheckbox = radioButton.closest('.form-checkbox')!
        this.cloneAndAppend(formCheckbox, container)

        break
      }
    }

    return {proceed: true}
  }

  cloneAndAppend(formCheckbox: Element, container: Element) {
    const clonedFormCheckbox = formCheckbox.cloneNode(true) as HTMLElement
    clonedFormCheckbox.removeAttribute('class')

    const input = clonedFormCheckbox.querySelector('input')!
    input.type = 'hidden'

    container.textContent = ''
    container.append(clonedFormCheckbox)
  }
}
