/* eslint i18n-text/no-en: off */
import type AutocompleteElement from '@github/auto-complete-element'
import type DetailsDialogElement from '@github/details-dialog-element'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

function businessTwoFactorInput(): HTMLInputElement {
  return document.querySelector<HTMLInputElement>('.js-business-enable-two-factor')!
}

function twoFactorSecureMethodsPresent(): boolean {
  return document.querySelector<HTMLInputElement>('.js-business-two-factor-secure-methods-currently-enabled') != null
}

function enablingTwoFactor(): boolean {
  const twoFactorCurrentlyEnabled =
    document.querySelector<HTMLInputElement>('.js-business-two-factor-currently-enabled')!.value === '0'
  return businessTwoFactorInput().checked && twoFactorCurrentlyEnabled
}

function twoFactorRequirementSettingChanged(): boolean {
  const enabled = document.querySelector<HTMLInputElement>('.js-business-two-factor-currently-enabled')!.value === '1'
  const enabling = businessTwoFactorInput().checked
  return (enabled && !enabling) || (!enabled && enabling)
}

function twoFactorRequirementSecureMethodSettingChanged(): boolean {
  const current = document.querySelector<HTMLInputElement>('.js-business-two-factor-secure-methods-currently-enabled')
  const checkbox = document.querySelector<HTMLInputElement>('.js-business-enable-two-factor-secure-methods')
  if (current == null || checkbox == null) {
    return false
  }

  const enabled = current.value === '1'
  const enabling = checkbox.checked
  return (enabled && !enabling) || (!enabled && enabling)
}

// update the 'submit' button state based on whether either of the two-factorsettings have changed
function updateTwoFactorRequirementSubmitButtonState() {
  const submitButton = document.querySelector<HTMLButtonElement>('.js-business-two-factor-submit-button')
  if (submitButton == null) {
    // no confirmation required, so dialog not rendered
    return
  }

  const disabled = !twoFactorRequirementSettingChanged() && !twoFactorRequirementSecureMethodSettingChanged()
  submitButton.setAttribute('aria-disabled', disabled.toString())
}

// Updates the two-factor requirement settings dialog. Because the dialog content is dependent on the state of the
// two nested checkboxes, we are unable to predict the dialog content on page load; rather we have to do it here
// whenever the state of either checkbox changes.
function updateTwoFactorConfirmationDialog() {
  if (!twoFactorSecureMethodsPresent()) {
    // if the secure methods checkbox isn't present, the dialog as rendered is correct
    return
  }

  const requirementChanged = twoFactorRequirementSettingChanged()
  const secureMethodsChanged = twoFactorRequirementSecureMethodSettingChanged()
  if (!requirementChanged && !secureMethodsChanged) {
    return
  }

  const requirementEnabled =
    document.querySelector<HTMLInputElement>('.js-business-two-factor-currently-enabled')!.value === '1'
  const secureMethodsEnabled =
    document.querySelector<HTMLInputElement>('.js-business-two-factor-secure-methods-currently-enabled')!.value === '1'

  const disablingRequirement = requirementEnabled && requirementChanged
  const enablingRequirement = !requirementEnabled && requirementChanged
  const disablingSecureMethods = secureMethodsEnabled && secureMethodsChanged
  const enablingSecureMethods = !secureMethodsEnabled && secureMethodsChanged

  const dialogTitle = document.querySelector<HTMLElement>('.js-business-two-factor-confirmation-dialog .Box-title')!
  const dialogButton = document.querySelector<HTMLElement>('.js-business-two-factor-confirmation-dialog-button')!
  const businessName = document.querySelector<HTMLInputElement>('#two-factor-business-name')!.value

  // hide all of the dialog contents so we can show the correct one depending on application/checkbox state
  for (const span of document.querySelectorAll<HTMLElement>('.js-business-two-factor-confirmation-dialog-body span')) {
    span.hidden = true
  }

  if (disablingRequirement) {
    // disabling the 2FA requirement completely
    dialogTitle.textContent = `Are you sure you want to remove the two-factor authentication requirement for all organizations in ${businessName}?`
    document.querySelector<HTMLElement>('#tfrc-disabling-requirement')!.hidden = false
    dialogButton.classList.remove('Button--primary')
    dialogButton.classList.add('Button--danger')
  } else if (enablingRequirement && enablingSecureMethods) {
    // enabling 2FA requirement with secure methods
    dialogTitle.textContent = `Are you sure you want to require two-factor authentication and only allow secure methods for all organizations in ${businessName}?`
    document.querySelector<HTMLElement>('#tfrc-enabling-requirement-with-secure-methods')!.hidden = false
    dialogButton.classList.remove('Button--danger')
    dialogButton.classList.add('Button--primary')
  } else if (enablingRequirement) {
    // enabling 2FA requirement w/o secure methods
    dialogTitle.textContent = `Are you sure you want to require two-factor authentication for all organizations in ${businessName}?`
    document.querySelector<HTMLElement>('#tfrc-enabling-requirement-without-secure-methods')!.hidden = false
    dialogButton.classList.remove('Button--danger')
    dialogButton.classList.add('Button--primary')
  } else if (disablingSecureMethods) {
    // disabling secure methods only
    dialogTitle.textContent = `Are you sure you want to allow all two-factor authentication methods for all organizations in ${businessName}?`
    document.querySelector<HTMLElement>('#tfrc-disabling-secure-methods-only')!.hidden = false
    dialogButton.classList.remove('Button--primary')
    dialogButton.classList.add('Button--danger')
  } else {
    // enabling secure methods only
    dialogTitle.textContent = `Are you sure you want to only allow secure methods of two-factor authentication for all organizations in ${businessName}?`
    document.querySelector<HTMLElement>('#tfrc-enabling-secure-methods-only')!.hidden = false
    dialogButton.classList.remove('Button--danger')
    dialogButton.classList.add('Button--primary')
  }
}

// bypass showing the two-factor confirmation dialog if we're not enabling two-factor. always show the confirmation dialog
// if the new two-factor secure methods setting is not present.
on('click', '.js-business-two-factor-submit-button', function (event) {
  if (!twoFactorSecureMethodsPresent()) {
    // secure methods feature not enabled, only show confirmation when enabling requirement
    if (!enablingTwoFactor()) {
      event.preventDefault()
    }
  } else {
    // update the dialog content which depends on the state of the checkboxes
    updateTwoFactorConfirmationDialog()

    // secure methods feature enabled, always show confirmation if top-level 2FA requirement is changing
    if (!twoFactorRequirementSettingChanged() && !twoFactorRequirementSecureMethodSettingChanged()) {
      event.preventDefault()
    }
  }
})

on('change', '.js-business-enable-two-factor', function () {
  updateTwoFactorRequirementSubmitButtonState()

  const requirementCheckbox = document.querySelector<HTMLInputElement>('.js-business-enable-two-factor')
  const secureMethodsRow = document.querySelector<HTMLElement>('.js-two-factor-secure-methods')
  const warningBanner = document.querySelector<HTMLElement>('.js-business-two-factor-requirement-warning-banner')
  if (requirementCheckbox == null || secureMethodsRow == null || warningBanner == null) {
    // need to tolerate these being missing from the DOM if the feature hasn't been fully rolled out
    return
  }

  if (requirementCheckbox.checked) {
    warningBanner.hidden = false
    secureMethodsRow.hidden = false
  } else {
    warningBanner.hidden = true
    secureMethodsRow.hidden = true
  }
})

on('change', '.js-business-enable-two-factor-secure-methods', function () {
  updateTwoFactorRequirementSubmitButtonState()
})

on('change', '.js-repo-types-allowed-radio', () => {
  const repoTypeCheckboxes = document.querySelectorAll<HTMLInputElement>('.js-repo-type-creation-checkbox')
  const repoTypeLabels = document.querySelectorAll<HTMLElement>('.js-repo-type-creation-label')
  for (const checkbox of repoTypeCheckboxes) {
    checkbox.disabled = false
  }
  for (const label of repoTypeLabels) {
    label.classList.remove('color-fg-muted')
  }
})

on('change', '.js-repo-types-disallowed-radio', () => {
  const repoTypeCheckboxes = document.querySelectorAll<HTMLInputElement>('.js-repo-type-creation-checkbox')
  const repoTypeLabels = document.querySelectorAll<HTMLElement>('.js-repo-type-creation-label')
  for (const checkbox of repoTypeCheckboxes) {
    checkbox.disabled = true
    checkbox.checked = false
  }
  for (const label of repoTypeLabels) {
    label.classList.add('color-fg-muted')
  }
})

on('change', '.js-provisioning-enabled-checkbox', () => {
  const provisioningEnabledPreviously = document.querySelector<HTMLInputElement>(
    '[name="business_saml_provider_provisioning_enabled_previously"]',
  )!

  if (provisioningEnabledPreviously.value === '0') {
    // setting disabled on the server, nothing to do yet.
    return
  }

  const userProvisioningCheckbox = document.querySelector<HTMLInputElement>('.js-provisioning-enabled-checkbox')!
  const deprovisioningCheckboxes = document.querySelectorAll<HTMLInputElement>(
    '.js-disable-when-user-provisioning-disabled-checkbox',
  )

  if (userProvisioningCheckbox.checked) {
    for (const checkbox of deprovisioningCheckboxes) {
      const previousValueInput = document.querySelector<HTMLInputElement>(`[name='${checkbox.id}_previously']`)!
      const formCheckbox = checkbox.closest<HTMLElement>('.js-deprovisioning-checkbox')!

      formCheckbox.classList.remove('checkbox-disabled')
      checkbox.removeAttribute('disabled')

      if (previousValueInput.value === '1') {
        // restore state for the last known server value
        checkbox.checked = true
      } else {
        checkbox.removeAttribute('checked')
      }
    }
  } else {
    // un-check, disable
    for (const checkbox of deprovisioningCheckboxes) {
      checkbox.setAttribute('disabled', 'disabled')
      checkbox.checked = false
      checkbox.closest<HTMLElement>('.js-deprovisioning-checkbox')!.classList.add('checkbox-disabled')
    }
  }
})

// view updates when user switches enterprise package setting
on('change', '.js-enterprise-package-settings-radio', event => {
  const elements = getPackagesElements()
  const target = event.currentTarget

  if (target == null) {
    return
  }

  const packageSettingValue = target.getAttribute('data-package-setting-value')

  for (const checkbox of elements.packageTypeCheckboxes) {
    if (packageSettingValue === 'managed') {
      checkbox.hidden = false
    } else {
      checkbox.hidden = true
    }
  }

  elements.packageTypeList.hidden = packageSettingValue === 'disabled'
  elements.packageSettingsManagedWarning.hidden = packageSettingValue !== 'managed'
  elements.packageSettingsDisabledWarning.hidden = packageSettingValue !== 'disabled'
  elements.updateSettingsButton.disabled = false
})

on('change', '.js-package-type-checkbox', () => {
  const elements = getPackagesElements()
  elements.updateSettingsButton.disabled = false
})

function getPackagesElements() {
  const packageTypeCheckboxes = document.querySelectorAll<HTMLInputElement>('.js-package-type-checkbox')
  const packageTypeList = document.querySelector<HTMLElement>('.js-package-types-list')!
  const packageSettingsManagedWarning = document.querySelector<HTMLElement>(
    '.js-enterprise-package-settings-managed-warning',
  )!
  const updateSettingsButton = document.querySelector<HTMLButtonElement>('.js-update-enterprise-package-settings')!
  const packageSettingsDisabledWarning = document.querySelector<HTMLElement>(
    '.js-enterprise-package-settings-disabled-warning',
  )!

  return {
    packageTypeCheckboxes,
    packageTypeList,
    packageSettingsManagedWarning,
    updateSettingsButton,
    packageSettingsDisabledWarning,
  }
}

function toggleBillingInformationEditForm(shown: boolean) {
  const editButtons = document.querySelectorAll<HTMLElement>('.js-billing-settings-billing-information-edit-button')
  for (const editButton of editButtons) {
    editButton.hidden = shown
  }

  const detailElements = document.querySelectorAll<HTMLElement>('.js-billing-settings-billing-information')
  for (const detailElement of detailElements) {
    detailElement.hidden = shown
  }

  const editFormContainer = document.querySelector<HTMLElement>(
    '.js-billing-settings-billing-information-form-container',
  )
  if (editFormContainer) {
    editFormContainer.hidden = !shown
  }
}

on('click', '.js-billing-settings-billing-information-edit-button', function () {
  toggleBillingInformationEditForm(true)
})

on('click', '.js-billing-settings-billing-information-cancel-button', function () {
  toggleBillingInformationEditForm(false)
})

function toggleBillingInvoiceEmailPreferenceForm(shown: boolean) {
  const editButtons = document.querySelectorAll<HTMLElement>(
    '.js-billing-settings-billing-invoice-email-preference-edit-button',
  )
  for (const editButton of editButtons) {
    editButton.hidden = shown
  }

  const editFormContainer = document.querySelector<HTMLElement>(
    '.js-billing-settings-billing-invoice-preference-form-container',
  )
  if (editFormContainer) {
    editFormContainer.hidden = !shown
  }
}

on('click', '.js-billing-settings-billing-invoice-email-preference-edit-button', function () {
  toggleBillingInvoiceEmailPreferenceForm(true)
})

on('change', '.js-pat-expiration-limit', event => {
  const target = event.target as HTMLInputElement
  if (target.value === 'custom') {
    document.getElementById('custom-pat-expiration-limit-wrapper')?.removeAttribute('hidden')
  } else {
    document.getElementById('custom-pat-expiration-limit-wrapper')?.setAttribute('hidden', 'true')
  }
})

document.addEventListener('turbo:frame-load', function (_e) {
  const dialog = document.getElementById('confirm-lifetimes') as HTMLDialogElement
  if (dialog) {
    dialog.showModal()
  }
})
