import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {fromEvent} from '@github-ui/subscription'

on('submit', '.js-dormant-users-reports-form', async event => {
  const form = event.currentTarget as HTMLFormElement
  const errorFlash = form.querySelector<HTMLElement>('.js-dormant-users-reports-error')!
  errorFlash.hidden = true

  event.preventDefault()

  try {
    const response = await fetchSafeDocumentFragment(document, form.action, {
      method: form.method,
      body: new FormData(form),
    })

    form.replaceWith(response)
  } catch (error) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    if (error.message.startsWith('HTTP 422')) {
      // eslint-disable-next-line i18n-text/no-en
      errorFlash.textContent = 'Only one report can be in progress at a time.'
    } else {
      // eslint-disable-next-line i18n-text/no-en
      errorFlash.textContent = 'Something went wrong when creating your new report.'
    }
    errorFlash.hidden = false
  }
})

observe('.js-in-progress-report-status-container', {
  subscribe: el =>
    fromEvent(el, 'socket:message', async function (event: Event) {
      const data = (event as CustomEvent).detail.data
      const div = event.currentTarget as HTMLDivElement
      const statusSpan = div.querySelector<HTMLElement>('.js-progress-status')!
      statusSpan.textContent = `in progress… ${data.status} users`
    }),
})
