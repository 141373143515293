// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

function toggleEmailNotification(toggle: 'on' | 'off') {
  const emailNotificationWrapper = document.getElementsByClassName('js-email-notifications')[0] as HTMLDivElement
  if (toggle === 'on') {
    emailNotificationWrapper.hidden = false
  } else if (toggle === 'off') {
    emailNotificationWrapper.hidden = true
  }
}

// show the email notifications checkbox if spending limits is selected
on('change', '.js-spending-limit-radio', function ({currentTarget}) {
  if (currentTarget instanceof HTMLInputElement && currentTarget.checked) {
    toggleEmailNotification('on')
  }
})

// hide the element if unlimited spending is selected
on('change', '.js-unlimited-spending-radio', function ({currentTarget}) {
  if (currentTarget instanceof HTMLInputElement && currentTarget.checked) {
    toggleEmailNotification('off')
  }
})
